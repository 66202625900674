import logo from '@/images/logo.svg';
import logo_symbol_wordmark from '@/images/logo-symbol-wordmark.svg';

export const WordmarkLogo: React.FC = () => (
  // <svg viewBox='0 0 261 66' className='logo logo--wordmark' role='img'>
  //   <title>Mastodon</title>
  //   <use xlinkHref='#logo-symbol-wordmark' />
  // </svg>
  <img src={logo_symbol_wordmark} alt='Mastodon' className='logo logo--icon' />
);

export const IconLogo: React.FC = () => (
  <svg viewBox='0 0 79 79' className='logo logo--icon' role='img'>
    <title>Mastodon</title>
    <use xlinkHref='#logo-symbol-icon' />
  </svg>
);

export const SymbolLogo: React.FC = () => (
  <img src={logo} alt='Mastodon' className='logo logo--icon' />
);
